<template>
        <v-app id="home" width="100vw">
            <NavBar/>
            <v-main :class="mainId">
                <v-alert
                    type="success"
                    elevation="6"
                    style="position: fixed; z-index: 10; margin-top: 30px; right: 10vw;"
                    v-if="alert.show"
                    class="primary-font f14"
                    transition="scale-transition"
                >
                    {{ alert.text }}
                </v-alert>
                <router-view/>
            </v-main>
            <Footer/>
        </v-app>

</template>

<script>
import { mapState, mapMutations} from 'vuex'
import NavBar from './NavBar.vue';
import Footer from './Footer.vue'

export default {
    name: 'layout-main',
    components: {
        NavBar,
        Footer,
    },
    computed: {
        ...mapState({
            alert: (state) => state.alert
        }),

        mainId(){
            return ['Contact Us', 'Home'].includes(this.$route.name) ? 'csc_bg' : ''
        }
    },
    methods:  {
        ...mapMutations(['alertMutation'])
    },

    watch: {
        alert(val) {
            if(val.show) {
                setTimeout(() => {
                    this.alertMutation({ text: '', show: false})
                }, 3000)
            }
        }
    }
}
</script>